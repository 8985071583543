import styled from 'styled-components';

export type IProps = {
  baseColor?: string;
  borderShadow?: string;
};

export const Container = styled.div`
  z-index: 1;
`;

export const CongratsArea = styled.div`
  width: 50%;
  margin: 0 auto;
  margin-top: 350px;
  border-radius: 15px;
  padding: 20px;
`;

export const SentConfirmation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 70% ;
`;

export const TitleText = styled.label`
  font-size: 36px;
  font-weight: 900;
  font-weight: bold;
  color: #f8f8ff;
  margin-top: 0;
  margin-bottom: 0;
`;

export const Description = styled.label`
  font-size: 24px;
  color: #f8f8ff;
  margin-top: 0;
  margin-bottom: 0;
  margin-top: 30px;
`;


export const SubmitButton = styled.button`
  min-width: 35%;
  margin-top: 20px;
  background: #4a5568;
  padding: 10px;
  border: none !important;
  color: #f8f8ff;
  line-height: 1.5;
  letter-spacing: -0.01em;
  list-style-position: outside;
  font-size: 140%;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
`;

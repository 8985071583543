import React from 'react';
import { TiThMenu } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';
import { Nav, NavItem, ToggleIcon } from './styles';

const NavBar: React.FC = () => {
  const navigate = useNavigate();

  const goToPage = async (page: string) => {
    await navigate(page);
  };

  return (
    <>
      <Nav>
        <NavItem onClick={() => goToPage('/')}>SERVIÇOS</NavItem>
        <NavItem onClick={() => goToPage('/metodologia')}>METODOLOGIA</NavItem>
        <NavItem onClick={() => goToPage('/contato')}>CONTATO</NavItem>
      </Nav>
      <ToggleIcon onClick={() => alert('foo')}>
        <TiThMenu />
      </ToggleIcon>
    </>
  );
};

export default NavBar;

import styled from 'styled-components';

export const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto;
  width: 100%;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const NavItem = styled.button`
  border: none;
  background-color: #1a202c;
  cursor: pointer;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 1em;
  margin-inline-end: 1em;
  line-height: 1.5;
  letter-spacing: -0.02em;
  list-style-position: outside;
  font-size: 100%;
  color: #f8f8ff;

  &:hover {
    border-bottom: 3px solid #7ed957;
  }
`;

export const ToggleIcon = styled.div`
  display: none;
  margin: 0.5em;
  padding: 0.5em;
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  width: 25px;
  height: 25px;

  @media screen and (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

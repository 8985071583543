import styled from 'styled-components';

export type IProps = {
  baseColor?: string;
  borderShadow?: string;
};

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'header header'
    'description image';

  min-height: 100vh;
  background: #1a202c;
  z-index: 1;

  @media (max-width: 820px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'header'
      'description'
      'image';
  }

  @media (max-width: 520px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'header'
      'description'
      'image';
  }
`;

export const Header = styled.header<IProps>(
  ({ baseColor, borderShadow }) => `
  transition: 0.3s ease-in;
  top: 0;
  width: 100%;
  background: ${baseColor};
  position: fixed;
  z-index: 19000;
  box-shadow: ${borderShadow};
  min-height: 10vh;
  grid-area: header;
  z-index: 9;`,
);

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
`;

export const ContentHeaderLogo = styled.div`
  width: 30%;
  align-items: center;
  min-height: 10vh;
`;

export const ContentHeaderNav = styled.div`
  width: 60%;
  align-items: center;
`;

export const Promo = styled.div`
  min-height: 5vh;
  background: #006400;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PromoDescription = styled.p`
  font-size: 16px;
  font-weight: 300;
  color: #f8f8ff;
  padding-left: 30px;
  padding-right: 30px;

  span {
    text-decoration: none;
    font-weight: 600;
    color: #f8f8ff;
    cursor: pointer;
  }
`;

export const Description = styled.div`
  background: #1a202c;
  min-height: 100vh;
  grid-area: description;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 820px) {
    height: 20vh;
  }

  @media screen and (max-width: 412px) {
    height: 50vh;
  }

  @media screen and (max-width: 390px) {
    height: 50vh;
  }

  @media screen and (max-width: 375px) {
    height: 130vh;
  }

  @media screen and (max-width: 360px) {
    height: 130vh;
  }
`;

export const DescriptionArea = styled.div`
  width: 80%;
  margin: 0 auto;
`;

export const ContentDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 150px;

  @media (max-width: 820px) {
    margin-top: 0px;
  }

  @media (max-width: 412px) {
    margin-top: 70px;
  }

  @media (max-width: 390px) {
    margin-top: 100px;
  }

  @media (max-width: 375px) {
    margin-top: 100px;
  }

  @media (max-width: 361px) {
    margin-top: 100px;
  }
`;

export const Title = styled.h1`
  font-size: 52px;
  font-weight: 900;
  font-weight: bold;
  color: #f8f8ff;
  margin-top: 0;
  margin-bottom: 0;
`;

export const SubTitle = styled.h2`
  font-size: 42px;
  font-weight: 700;
  color: #f8f8ff;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;

  span {
    color: #7ed957;
    margin-left: 1px;
    font-weight: 700;
    line-height: 72px;
    font-weight: bold;
    font-style: italic;
  }
`;

export const DescriptionText1 = styled.p`
  letter-spacing: -0.01em;
  list-style-position: outside;
  color: #f8f8ff;
  font-size: 21px;
  font-weight: 200;
  line-height: 29px;

  span {
    font-weight: 500;
  }
`;

export const InvitationText1 = styled.p`
  letter-spacing: -0.01em;
  list-style-position: outside;
  color: #f8f8ff;
  font-size: 21px;
  font-weight: 200;
  line-height: 29px;

  span {
    font-weight: 500;
  }
`;

export const InviteButton = styled.button`
  min-width: 35%;
  margin-top: 20px;
  background: #4a5568;
  padding: 10px;
  border: none !important;
  color: #f8f8ff;
  line-height: 1.5;
  letter-spacing: -0.01em;
  list-style-position: outside;
  font-size: 140%;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
`;

export const Image = styled.div`
  background: #1a202c;
  height: 100vh;
  grid-area: image;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 820px) {
    height: 100vh;
    display: none;
  }

  @media screen and (max-width: 412px) {
    height: 50vh;
  }

  @media screen and (max-width: 390px) {
    height: 50vh;
  }

  @media screen and (max-width: 361px) {
    height: 50vh;
  }
`;

export const ImageContent = styled.div`
  margin-top: 120px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  @media (max-width: 820px) {
    margin-top: 0px;
  }

  @media (max-width: 412px) {
    margin-top: 0px;
  }

  @media (max-width: 390px) {
    margin-top: 0px;
  }

  @media (max-width: 361px) {
    margin-top: 0px;
  }
`;

export const ImageFloating = styled.img`
  @media screen (max-width: 1920px) {
    width: 600px;
  }

  @media screen (max-width: 1720px) {
    width: 600px;
  }

  @media screen and (max-width: 1496px) {
    width: 550px;
  }

  @media screen and (max-width: 1380px) {
    width: 450px;
  }

  @media screen and (max-width: 1240px) {
    width: 400px;
  }

  @media screen and (max-width: 1024px) {
    width: 350px;
  }

  @media screen and (max-width: 820px) {
    width: 500px;
  }

  @media screen and (max-width: 680px) {
    width: 400px;
  }

  @media screen and (max-width: 412px) {
    width: 250px;
  }

  @media screen and (max-width: 390px) {
    width: 250px;
  }

  @media screen and (max-width: 361px) {
    width: 250px;
  }
`;

import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { Container, Text } from './styles';

const Section2: React.FC = () => {
  return (
    <Container>
      <Fade>
        <Text>
          Somos especialistas em desenvolver idéias e colocar projetos em prática.
        </Text>
      </Fade>
    </Container>
  );
};

export default Section2;

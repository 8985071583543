import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  row-gap: 30px;
  grid-template-areas:
    'banner banner banner banner'
    'box box box box';

  min-height: 100vh;
  z-index: 5;
  margin-top: 50px;

  @media (max-width: 820px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'banner'
      'box';
  }

  @media (max-width: 520px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'banner'
      'box';
  }
`;

export const BoxArea = styled.div`
  width: 100%;
  background: #7ed957;
`;

export const Box = styled.div`
  min-height: 30vh;
  grid-area: 'box';
  background: #2d3748;
  color: #f8f8ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin: 0;
    padding: 0;

    letter-spacing: -0.01em;
    list-style-position: outside;
    color: #f8f8ff;
    font-size: 36px;
    font-weight: 500;
    line-height: 29px;
  }

  p {
    letter-spacing: -0.01em;
    list-style-position: outside;
    color: #f8f8ff;
    font-size: 28px;
    font-weight: 300;
    line-height: 29px;
  }
`;

export const Banner = styled.div`
  display: grid;
  min-height: 70vh;
  grid-area: banner;
  grid-gap: 5px;
  column-gap: 40px;
  grid-template-areas: 'img text';
  width: 90%;
  margin: 0 auto;
`;

export const ImgBanner = styled.div`
  grid-area: 'img';
  display: flex;
  align-items: center;
  justify-content: start;

  @media (max-width: 820px) {
    height: 100vh;
    display: none;
  }
`;

export const TextBanner = styled.div`
  grid-area: 'text';
`;

export const TextBannerArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
`;

export const Title = styled.h1`
  font-size: 52px;
  font-weight: 700;
  color: #f8f8ff;
  font-weight: bold;
  margin-top: 0;

  span {
    color: #7ed957;
    margin-left: 1px;
    font-weight: 700;
    line-height: 72px;
    font-weight: bold;
    font-style: italic;
  }
`;

export const Description = styled.p`
  letter-spacing: -0.01em;
  list-style-position: outside;
  color: #f8f8ff;
  font-size: 21px;
  font-weight: 200;
  line-height: 29px;

  span {
    font-weight: 500;
  }

  a {
    font-weight: 700;
    font-size: 18px;
    cursor: pointer;
    text-decoration: none;
    color: #7ed957;
  }
`;

export const Button = styled.button`
  min-width: 35%;
  margin-top: 20px;
  background: #4a5568;
  padding: 10px;
  border: none !important;
  color: #f8f8ff;
  line-height: 1.5;
  letter-spacing: -0.01em;
  list-style-position: outside;
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
`;

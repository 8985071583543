/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  FormArea,
  Container,
  Title,
  FormFields,
  TextArea,
  Input,
  SubmitButton,
} from './styles';
import api from './api';

interface IFormData {
  name?: string;
  email?: string;
  phone?: string;
  message?: string;
}

const ContactForm: React.FC = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: IFormData) => {
    try {
      const sendEmail = await api.post('/', {
        senderName: data.name,
        senderEmail: data.email,
        subject: '[CONTATO] - Solicitação site Codar BH',
        senderPhone: data.phone,
        message: data.message,
      });

      if (sendEmail.status === 200) navigate('/congrats');

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <FormArea>
        <Title>Informe seus dados para contato.</Title>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormFields>
            <Input
              type="text"
              title="Qual é o seu nome?"
              placeholder="Qual é o seu nome? *"
              {...register('name', { required: true, maxLength: 100 })}
            />
            {errors.name && errors.name.type === 'required' && (
              <span style={{ color: 'red' }}>
                Informe o seu nome por favor.
              </span>
            )}
            <Input
              type="text"
              placeholder="Qual seu Email? *"
              {...register('email', { required: true, maxLength: 100 })}
            />
            {errors.email && errors.email.type === 'required' && (
              <span style={{ color: 'red' }}>
                Informe o seu email por favor.
              </span>
            )}
            <Input
              type="text"
              placeholder="Telefone *"
              {...register('phone', { required: true, maxLength: 100 })}
            />
            {errors.phone && errors.phone.type === 'required' && (
              <span style={{ color: 'red' }}>
                Informe o seu telefone por favor.
              </span>
            )}
            <TextArea
              placeholder="Em que podemos te ajudar?"
              {...register('message', { required: true })}
            />
            {errors.message && errors.message.type === 'required' && (
              <span style={{ color: 'red' }}>
                Informe o seu telefone por favor.
              </span>
            )}

            <SubmitButton type="submit">Enviar</SubmitButton>
          </FormFields>
        </form>
      </FormArea>
    </Container>
  );
};

export default ContactForm;

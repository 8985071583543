import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { FiCheckCircle } from 'react-icons/fi';
import {
  Container,
  DescriptionArea,
  Box,
  Title,
  TextArea,
  Description,
  GridArea,
  Card,
  CardTitle,
  CardBody,
  CardItem,
} from './styles';

const Section3: React.FC = () => {
  return (
    <Container>
      <GridArea>
        <DescriptionArea>
          <TextArea>
            <Fade cascade damping={0.3}>
              <Title>
                Mais do que <span>codificar</span>.
              </Title>
              <Description>
                A <span>etapa da codificação</span> de um software é muito
                <span> importante</span>, mas é apenas uma
                <span> parte do processo</span>. Nosso trabalho considera o
                desenvolvimento do seu produto como uma <span>jornada</span>,
                que começa a partir de uma <span>ideia</span>, ou seja,{' '}
                <span>um conceito</span>.<span> Acompanhamos</span> todas as
                etapas do desenvolvimento desde sua <span>conceituação</span>,
                visando atingir os <span>objetivos</span> do seu negócio,
                percorrendo pelo caminho do <span>design, </span> focado na{' '}
                <span>experiência do usuário</span>, aplicando a{' '}
                <span>metodologia</span> correta com métricas de{' '}
                <span>resultados</span>.
              </Description>
            </Fade>
          </TextArea>
        </DescriptionArea>
        <Fade cascade damping={0.3}>
          <Box>
            <Card>
              <CardTitle>Conceituação</CardTitle>
              <CardBody>
                <span>Planejamento </span> é a chave. Nesta etapa, transformamos uma ideia em um modelo.
              </CardBody>
              <CardItem>
                <FiCheckCircle />
                <p>Brainstoming</p>
              </CardItem>
              <CardItem>
                <FiCheckCircle />
                <p>Definição do produto</p>
              </CardItem>
              <CardItem>
                <FiCheckCircle />
                <p>Qualificação do ROI</p>
              </CardItem>
            </Card>
          </Box>
          <Box>
            <Card>
              <CardTitle> Experimentação</CardTitle>
              <CardBody>
                <span>Experimentar </span> para conquistar. O que acha de
                <span> poder ver</span> como vai ficar sua aplicação{' '}
                <span>antes do início do desenvolvimento</span>?
              </CardBody>
              <CardItem>
                <FiCheckCircle />
                <p>UI design</p>
              </CardItem>
              <CardItem>
                <FiCheckCircle />
                <p>UX design</p>
              </CardItem>
              <CardItem>
                <FiCheckCircle />
                <p>Design thinking</p>
              </CardItem>
            </Card>
          </Box>
          <Box>
            <Card>
              <CardTitle>Metodologia</CardTitle>
              <CardBody>
                A definição do produto e <span> resultados esperados</span>, e a
                definição do <span>ROI </span> esperado, são nossa métrica de{' '}
                <span>entrega das funcionalidades</span>.
              </CardBody>
              <CardItem>
                <FiCheckCircle />
                <p>Scrum</p>
              </CardItem>
              <CardItem>
                <FiCheckCircle />
                <p>Feature Driven-Development</p>
              </CardItem>
              <CardItem>
                <FiCheckCircle />
                <p>Extreme programming</p>
              </CardItem>
            </Card>
          </Box>
        </Fade>
      </GridArea>
    </Container>
  );
};

export default Section3;

import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Banner,
  ImgBanner,
  TextBanner,
  TextBannerArea,
  Title,
  Description,
  Button,
} from './styles';

const Section5: React.FC = () => {
  const navigate = useNavigate();

  const goToContact = async () => {
    await navigate('/contato');
  };

  return (
    <Container>
      <Banner>
        <Fade direction="left">
          <ImgBanner>
            <img
              alt="cloud-general"
              src={`${process.env.PUBLIC_URL}/assets/reshot-illustration-business-target-FEQSN2RCKP-876d9.png`}
              width="500px"
            />
          </ImgBanner>
        </Fade>
        <TextBanner>
          <TextBannerArea>
            <Fade direction="right" cascade damping={0.2}>
              <Title>
                Processos sempre existirão. <span>A gestão</span> correta gera o
                sucesso do <span>resultado</span>.
              </Title>
              <Description>
                Vamos <span>conversar </span>sobre como podemos te{' '}
                <span> ajudar </span> na construção do seu{' '}
                <span> produto </span> ou <span>serviço</span> digital?
              </Description>
              <Button onClick={() => goToContact()}>Saiba mais</Button>{' '}
            </Fade>
          </TextBannerArea>
        </TextBanner>
      </Banner>
      <Fade cascade damping={0.2}>
        <Box>
          <h1>SCRUM</h1>
        </Box>
        <Box>
          <h1>LEAN</h1>
        </Box>
        <Box>
          <h1>KANBAN</h1>
        </Box>
        <Box>
          <h1>SMART</h1>
        </Box>
      </Fade>
    </Container>
  );
};

export default Section5;

import React from 'react';
import Footer from '../Footer/index';
import Intro from './Sections/Intro';
import Section3 from './Sections/Section3';
import Section4 from './Sections/Section4';
import Section5 from './Sections/Section5';

const Metodology: React.FC = () => {
  return (
    <>
      <Intro />
      <Section3 />
      <Section4 />
      <Section5 />
      <Footer />
    </>
  );
};

export default Metodology;

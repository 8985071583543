import styled from 'styled-components';

export const Container = styled.div`
  background: #1a365d;
`;

export const GridArea = styled.div`
  display: grid;
  grid-gap: 5px;
  column-gap: 40px;
  grid-template-areas:
    'content content content content'
    'box box box box';

  min-height: 100vh;
  z-index: 1;
  width: 90%;
  margin: 0 auto;

  @media (max-width: 820px) {
    grid-template-areas:
      'content content'
      'box box';
  }

  @media (max-width: 520px) {
    grid-template-areas:
      'content'
      'box';
  }
`;
export const DescriptionArea = styled.div`
  grid-area: content;
  min-height: 50vh;
`;

export const Box = styled.div`
  grid-area: 'box';
  height: 50vh;
`;

export const TextArea = styled.div`
  margin: 0 auto;
  margin-top: 70px;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 52px;
  font-weight: 700;
  color: #f8f8ff;

  span {
    color: #7ed957;
  }
`;

export const Description = styled.p`
  margin-top: 50px;
  line-height: 1.5;
  letter-spacing: -0.01em;
  list-style-position: outside;
  font-size: 21px;
  font-weight: 300;
  color: #f8f8ff;

  span {
    font-weight: bold;
  }
`;

export const Card = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #2c5282;
  border-radius: 10px;
  padding: 0px 20px 0px 20px;
  box-shadow: 5px 5px 15px 3px rgba(1, 1, 1, 0.1);

  @media (max-width: 520px) {
    justify-content: center;
  }
`;

export const CardImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export const CardTitle = styled.h3`
  color: #f8f8ff;
  margin: 0;
  padding: 0;
  margin-top: 20px;
  font-size: 24px;

  @media (max-width: 520px) {
    font-size: 28px;
  }
`;

export const CardBody = styled.p`
  line-height: 1.5;
  letter-spacing: -0.01em;
  list-style-position: outside;
  font-size: 18px;
  font-weight: 300;
  color: #f8f8ff;

  span {
    font-weight: 600;
  }
`;

export const CardItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  p {
    margin: 0;
    padding: 0;
    margin-left: 5px;
    line-height: 1.5;
    letter-spacing: -0.01em;
    list-style-position: outside;
    font-size: 14px;
    font-weight: 300;
    color: #f8f8ff;
  }
`;

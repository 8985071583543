import React from 'react';
import {
  RiMailLine,
  RiWhatsappLine,
  RiMapPin2Line,
  RiFacebookCircleLine,
  RiTwitterLine,
  RiInstagramLine,
  RiLinkedinLine,
} from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import Logo from '../../components/Logo';
import {
  Contact,
  ContactItem,
  ContactItemDescription,
  Container,
  FooterArea,
  Links,
  LogoCodar,
  Social,
  Title,
} from './styles';

const Footer: React.FC = () => {
  const navigate = useNavigate();

  const goToPage = async (page: string) => {
    await navigate(page);
  };
  return (
    <Container>
      <FooterArea>
        <LogoCodar>
          <Logo />
        </LogoCodar>
        <Links>
          <Title>Links</Title>
          <button type="button" onClick={() => goToPage('/')}>
            Serviços
          </button>
          <button type="button" onClick={() => goToPage('/metodologia')}>
            Metodologia
          </button>
          <button type="button" onClick={() => goToPage('/')}>
            Contato
          </button>
        </Links>
        <Contact>
          <Title>Contato</Title>
          <ContactItem>
            <RiMailLine color="#f8f8ff" />
            <ContactItemDescription>
              contato@codarbh.com.br
            </ContactItemDescription>
          </ContactItem>
          <ContactItem>
            <RiWhatsappLine color="#f8f8ff" />
            <ContactItemDescription>(31) 9 9510-2858</ContactItemDescription>
          </ContactItem>
          <ContactItem>
            <RiMapPin2Line color="#f8f8ff" />
            <ContactItemDescription>Av. Santa Rosa, 231</ContactItemDescription>
          </ContactItem>
          <ContactItem>
            <RiWhatsappLine color="#1a202c" />
            <ContactItemDescription>
              Pampulha, Belo Horizonte - MG
            </ContactItemDescription>
          </ContactItem>
          <ContactItem>
            <RiWhatsappLine color="#1a202c" />
            <ContactItemDescription>31275-260</ContactItemDescription>
          </ContactItem>
        </Contact>
        <Social>
          <Title>Mídias sociais</Title>
          <a
            href="https://www.facebook.com/people/Codar-BH/100086912266948/"
            target="_blank"
            rel="noreferrer"
          >
            <RiFacebookCircleLine size="30px" />
          </a>
          <a
            href="https://twitter.com/codarbh"
            target="_blank"
            rel="noreferrer"
          >
            <RiTwitterLine size="30px" />
          </a>
          <a
            href="https://www.instagram.com/codarbh/"
            target="_blank"
            rel="noreferrer"
          >
            <RiInstagramLine size="30px" />
          </a>
          <a
            href="https://br.linkedin.com/company/codarbh"
            target="_blank"
            rel="noreferrer"
          >
            <RiLinkedinLine size="30px" />
          </a>
        </Social>
      </FooterArea>
    </Container>
  );
};

export default Footer;

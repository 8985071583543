import styled from 'styled-components';

export const Container = styled.div`
  height: 20vh;
  width: 100% ;
  background: #2d3748;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.p`
  width: 100%;
  color: #f8f8ff;
  line-height: 1.5;
  letter-spacing: -0.01em;
  list-style-position: outside;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
`;

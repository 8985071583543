import React from 'react';
import { Fade } from 'react-awesome-reveal';
import {
  Container,
  DescriptionArea,
  SubTitle,
  Box,
  Title,
  TextArea,
  Description,
  GridArea,
  Card,
  CardTitle,
  CardBody,
  SubTitleArea,
} from './styles';

const Section3: React.FC = () => {
  return (
    <Container>
      <GridArea>
        <DescriptionArea>
          <TextArea>
            <Fade cascade damping={0.3}>
              <Title>
                Metodologias <span>ágeis</span>.
              </Title>
              <Description>
                Em todos os <span>projetos</span> contratados utilizamos{' '}
                <span>métodos inteligentes</span> como <span>solução</span> para
                desenvolver uma execução correta das <span>ações</span>, de
                forma que o <span>objetivo</span> do nosso cliente seja
                alcançado no <span>tempo previsto</span>. Buscamos{' '}
                <span>simplificar</span> a maneira em que os{' '}
                <span>projetos são executados</span>, causando um{' '}
                <span>impacto favorável</span>
                no resultado <span>esperado</span>.
              </Description>
            </Fade>
            <SubTitleArea>
              <Fade cascade damping={0.3}>
                <SubTitle>
                  Pilares do manifesto <span>ágil</span>.
                </SubTitle>
              </Fade>
            </SubTitleArea>
          </TextArea>
        </DescriptionArea>
        <Fade cascade damping={0.3}>
          <Box>
            <Card>
              <CardTitle>Comunicação</CardTitle>
              <CardBody>
                <span>indivíduos</span> e <span>interações</span> são mais{' '}
                <span>importantes</span> que <span>processos</span> e{' '}
                <span>ferramentas</span>
              </CardBody>
            </Card>
          </Box>
          <Box>
            <Card>
              <CardTitle>Praticidade</CardTitle>
              <CardBody>
                <span>Software</span> em <span>funcionamento</span> é mais{' '}
                <span>importante</span> do que documentação{' '}
                <span>abrangente</span>
              </CardBody>
            </Card>
          </Box>
          <Box>
            <Card>
              <CardTitle>Colaboração</CardTitle>
              <CardBody>
                A <span>colaboração</span> com o <span>cliente</span> é mais
                importate que a <span>negociação de contratos</span>.
              </CardBody>
            </Card>
          </Box>
          <Box>
            <Card>
              <CardTitle>Flexibilidade</CardTitle>
              <CardBody>
                A <span>resposta</span> frente à <span>mudanças</span> tem maior{' '}
                <span>relevância</span> do que seguir um <span>plano</span>.{' '}
              </CardBody>
            </Card>
          </Box>
        </Fade>
      </GridArea>
    </Container>
  );
};

export default Section3;

import React from 'react';
import { Container, Image, Link, TextLogo } from './styles';

const Logo: React.FC = () => {
  return (
    <Container>
      <Link href="/">
        <Image src={`${process.env.PUBLIC_URL}/assets/logo-codar.png`} />
        <TextLogo>
          CODAR<span>BH</span>
        </TextLogo>
      </Link>
    </Container>
  );
};

export default Logo;

import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Banner,
  ImgBanner,
  TextBanner,
  TextBannerArea,
  Title,
  Description,
  Button,
} from './styles';

const Section5: React.FC = () => {
  const navigate = useNavigate();

  const goToMetodology = async () => {
    await navigate('/metodologia');
  };
  return (
    <Container>
      <Banner>
        <Fade direction="left">
          <ImgBanner>
            <img
              alt="cloud-general"
              src={`${process.env.PUBLIC_URL}/assets/reshot-illustration-business-target-2UZ7DGFAN4-21d2c.png`}
              width="500px"
            />
          </ImgBanner>
        </Fade>
        <TextBanner>
          <TextBannerArea>
            <Fade direction="right" cascade damping={0.2}>
              <Title>
                Juntos, teremos bons <span>resultados.</span>
              </Title>
              <Description>
                Vamos <span>conversar </span>sobre como podemos te{' '}
                <span> ajudar </span> na construção do seu{' '}
                <span> produto </span> ou <span>serviço</span> digital?
              </Description>
              <Button onClick={() => goToMetodology()}>
                CONHEÇA NOSSA METODOLOGIA
              </Button>{' '}
            </Fade>
          </TextBannerArea>
        </TextBanner>
      </Banner>
      <Fade cascade damping={0.2}>
        <Box>
          <h1>8.000+</h1>
          <p>Pessoas online</p>
        </Box>
        <Box>
          <h1>60+</h1>
          <p>Empresas atendidas</p>
        </Box>
        <Box>
          <h1>20+</h1>
          <p>anos de experiência</p>
        </Box>
        <Box>
          <h1>12+</h1>
          <p>Tecnologias envolvidas</p>
        </Box>
      </Fade>
    </Container>
  );
};

export default Section5;

import React, { useEffect, useState } from 'react';
import { motion, useCycle } from 'framer-motion';
import { Fade } from 'react-awesome-reveal';
import { useLocation } from 'react-router-dom';
import Logo from '../../../../components/Logo';
import NavBar from '../../../../components/NavBar';
import {
  Container,
  ContentDescription,
  ContentHeader,
  ContentHeaderLogo,
  ContentHeaderNav,
  Description,
  DescriptionArea,
  DescriptionText1,
  Header,
  Image,
  ImageContent,
  ImageFloating,
  InvitationText1,
  Promo,
  PromoDescription,
  Title,
} from './styles';

const Intro: React.FC = () => {
  const [isRolling, setIsRolling] = useState<boolean>(false);
  const [animation, cycleAnimation] = useCycle('animationOne', 'animationTwo');
  const { pathname } = useLocation();

  const YoImageVariants = {
    animationOne: { x: 0, opacity: 1 },
    animationTwo: {
      y: [0, -5],
      opacity: 1,
      transition: { yoyo: Infinity, ease: 'easeIn' },
    },
  };

  window.addEventListener('scroll', () => {
    if (window.scrollY > 10) {
      setIsRolling(true);
    } else if (window.scrollY < 10) {
      setIsRolling(false);
    }
  });

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      window.scrollTo(0, 0);
      setTimeout(() => {
        cycleAnimation();
      }, 1000);
    }
    return () => {
      isMounted = false;
    };
  }, [cycleAnimation, pathname]);

  return (
    <Container>
      <Header
        baseColor="#1A202C"
        borderShadow={isRolling ? '0px 10px 10px rgb( 0 0 0  / 10%)' : 'none'}
      >
        <ContentHeader>
          <ContentHeaderLogo>
            <Logo />
          </ContentHeaderLogo>
          <ContentHeaderNav>
            <NavBar />
          </ContentHeaderNav>
        </ContentHeader>
        <Promo>
          <PromoDescription>
            Ganhe 1 ano de hospedagem na AWS, fazendo o seu site conosco.
            <span> Saiba como.</span>
          </PromoDescription>
        </Promo>
      </Header>
      <Description>
        <DescriptionArea>
          <ContentDescription>
            <Fade direction="left" cascade damping={0.3}>
              <Title>Nossa metodologia.</Title>
            </Fade>
            <Fade direction="left">
              <DescriptionText1>
                Implementar as <span>metodologias ágeis</span> no seu projeto, é
                o <span>primeiro</span> passo para sua empresa ganhar uma{' '}
                <span>posição de destaque</span> em um cenário de{' '}
                <span>alta concorrência</span> do mercado atual. Utilizamos os
                principais <span>métodos</span> para construir seu{' '}
                <span>projeto</span> e fazer com que seu{' '}
                <span>negócio decole de vez</span>!
              </DescriptionText1>
              <InvitationText1>
                <span>Garantimos</span> a entrega <span>planejada</span> em
                relação aos <span>objetivos definidos</span> e com uma execução{' '}
                <span>priorizada</span> com base no seu{' '}
                <span>retorno de investimento</span>.
              </InvitationText1>
            </Fade>
          </ContentDescription>
        </DescriptionArea>
      </Description>
      <Image>
        <ImageContent>
          <motion.div
            variants={YoImageVariants}
            animate={animation}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Fade direction="right">
              <ImageFloating
                alt="codarbh"
                src={`${process.env.PUBLIC_URL}/assets/reshot-illustration-brainstorming-process-AY6JQC5UNT.png`}
                width="600px"
              />
            </Fade>
          </motion.div>
        </ImageContent>
      </Image>
    </Container>
  );
};

export default Intro;

import React from 'react';
import { Fade } from 'react-awesome-reveal';
import {
  Container,
  DescriptionArea,
  Box,
  Title,
  TextArea,
  Description,
  GridArea,
  Card,
  CardTitle,
  CardBody,
} from './styles';

const Section4: React.FC = () => {
  return (
    <Container>
      <GridArea>
        <DescriptionArea>
          <TextArea>
            <Fade cascade damping={0.3}>
              <Title>
                As <span>vantagens</span> de utilizar metodologias ágeis.
              </Title>
              <Description>
                Desenvolver uma <span>mentalidade ágil</span> é a melhor solução
                para construir uma <span>execução correta das ações</span>, de
                forma que o <span>objetivo final</span> da empresa seja atendido
                no <span>prazo estipulado</span>. A implantação das metodologias
                ágeis busca <span>simplificar</span> a maneira em que os
                projetos são <span>executados</span>, impactando{' '}
                <span>positivamente no resultado final</span>.
              </Description>
              <Description>
                A frequente prática de <span>testes</span> no processo, garante
                uma <span>efetividade</span> maior do sistema e isso traz{' '}
                <span>inúmeros benefícios</span> em relação às abordagens
                tradicionais.
              </Description>
            </Fade>
          </TextArea>
        </DescriptionArea>
        <Fade cascade damping={0.2}>
          <Box>
            <Card>
              <CardTitle>Velocidade</CardTitle>
              <CardBody>
                Existe uma maior <span>agilidade</span> e <span>eficiênci</span>{' '}
                na execução dos processos e, consequentemente, nas{' '}
                <span>entregas</span> do projeto, tendo em vista que o cliente
                <span>acompanha</span> e <span>valida</span> o projeto em todas
                as etapas.
              </CardBody>
            </Card>
          </Box>
          <Box>
            <Card>
              <CardTitle>Qualidade</CardTitle>
              <CardBody>
                Como a <span>interação</span> entre a <span>empresa</span> e o{' '}
                <span>cliente</span> é maior, etapas de <span>teste</span> e{' '}
                <span>validação</span>
                são <span>frequentemente realizados</span>, aumentando assim a{' '}
                <span>qualidade do produto</span> final e garantindo a sua
                satisfação com os resultados.
              </CardBody>
            </Card>
          </Box>
          <Box>
            <Card>
              <CardTitle>Produtividade</CardTitle>
              <CardBody>
                As <span>equipes</span> de <span>produção</span> não precisam se{' '}
                <span>preocupar</span> com processos <span>burocráticos</span>,
                já que a <span>simplificação</span> desses procedimentos garante
                que todos os <span>esforços</span> estejam voltados para a
                criação de uma <span>solução mais completa</span>.
              </CardBody>
            </Card>
          </Box>
          <Box>
            <Card>
              <CardTitle>Personalização</CardTitle>
              <CardBody>
                Com uma <span>maior interação</span> entre <span>nós</span> e{' '}
                <span> nossos clientes</span>, sugestões sempre são
                apresentadas, fazendo com que o time responsável pense em
                <span>soluções</span> antes de o projeto ser concluído.
              </CardBody>
            </Card>
          </Box>
        </Fade>
      </GridArea>
    </Container>
  );
};

export default Section4;

import styled from 'styled-components';

export const Container = styled.div`
  background: #1a202c;
`;

export const FooterArea = styled.div`
  display: grid;
  row-gap: 30px;
  grid-template-areas: 'logo links contact social';

  min-height: 50vh;
  z-index: 6;
  width: 90%;
  margin: 0 auto;

  @media (max-width: 820px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'logo links'
      'contact social';
  }

  @media (max-width: 520px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'logo'
      'links'
      'contact'
      'social';
  }
`;

export const LogoCodar = styled.div`
  grid-area: 'logo';
  display: flex;
  justify-content: start;
  margin-top: 30px;
`;

export const Title = styled.h1`
  grid-area: 'links';
  display: flex;
  flex-direction: column;
  justify-content: start;
  color: #f8f8ff;
  margin: 30px 0 20px 0;
  padding: 0;
`;

export const Links = styled.div`
  grid-area: 'links';
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 30px;

  button {
    border: none;
    background-color: #1a202c;
    cursor: pointer;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-end: 1em;
    line-height: 1.5;
    letter-spacing: -0.02em;
    list-style-position: outside;
    font-size: 100%;
    color: #f8f8ff;
    text-align: left;
`;

export const Contact = styled.div`
  grid-area: 'contact';
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 30px;
`;

export const ContactItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-top: 10px;
`;

export const ContactItemDescription = styled.p`
  color: #f8f8ff;
  letter-spacing: -0.01em;
  list-style-position: outside;
  font-size: 18px;
  font-weight: 200;
  line-height: 29px;
  margin-left: 5px;
  margin-top: 0;
  margin-bottom: 0;
`;

export const Social = styled.div`
  grid-area: 'social';
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 30px;

  a {
    width: 35px;
    text-decoration: none;
    color: #f8f8ff;
    letter-spacing: -0.01em;
    list-style-position: outside;
    font-size: 18px;
    font-weight: 200;
    line-height: 29px;
    padding: 5px 0 0 0;
  }
`;

import React from 'react';
import { Fade } from 'react-awesome-reveal';
import {
  Container,
  DescriptionArea,
  Box,
  Title,
  TextArea,
  Description,
  GridArea,
  Card,
  CardTitle,
  CardBody,
  CardImg,
} from './styles';

const Section4: React.FC = () => {
  return (
    <Container>
      <GridArea>
        <DescriptionArea>
          <TextArea>
            <Fade cascade damping={0.3}>
              <Title>
                É de <span>cloud</span> que seu negócio precisa.
              </Title>
              <Description>
                Não é mais uma questão de <span>&quot;se&quot;</span>, mas sim
                de <span>&quot;quando&quot;</span>. A{' '}
                <span>tecnologia da informação</span> vem sofrendo um grande
                <span> avanço</span> e a <span>corrida</span> das empresas para
                se adequarem a esta <span>nova tendência</span> não é mais
                condicional. O tema <span>CLOUD</span> deve ser priorizado na{' '}
                <span>agenda de implementação</span> da sua empresa, uma vez que
                aumenta a <span>agilidade</span> e <span>segurança</span> de acesso às informações da
                corporação.
              </Description>
            </Fade>
          </TextArea>
        </DescriptionArea>
        <Fade cascade damping={0.2}>
          <Box>
            <Card>
              <CardImg>
                <img
                  alt="cloud-general"
                  src={`${process.env.PUBLIC_URL}/assets/reshot-icon-cloud-value-WH3TSZ9QV8.svg`}
                  width="80px"
                />
              </CardImg>
              <CardTitle>Praticidade</CardTitle>
              <CardBody>
                Sem <span>complicação</span>. Transfira a <span>estrutura</span>{' '}
                da sua empresa para a nuvem.
              </CardBody>
            </Card>
          </Box>
          <Box>
            <Card>
              <CardImg>
                <img
                  alt="cloud-general"
                  src={`${process.env.PUBLIC_URL}/assets/reshot-icon-secure-cloud-QDWPNFJVE7.svg`}
                  width="80px"
                />
              </CardImg>
              <CardTitle>Segurança</CardTitle>
              <CardBody>
                Garanta a <span>segurança</span> da suas{' '}
                <span>informações</span> e ofereça <span>credibilidade</span>{' '}
                aos seus clientes.
              </CardBody>
            </Card>
          </Box>
          <Box>
            <Card>
              <CardImg>
                <img
                  alt="cloud-general"
                  src={`${process.env.PUBLIC_URL}/assets/reshot-icon-data-server-AZXTE5DNSL.svg`}
                  width="80px"
                />
              </CardImg>
              <CardTitle>Disponibilidade</CardTitle>
              <CardBody>
                Alta <span>disponibilidade</span> de acesso
                aos seus <span>serviços</span> em <span>servidores alocados</span> em várias{' '}
                regiões do mundo com <span> total segurança</span> .
              </CardBody>
            </Card>
          </Box>
          <Box>
            <Card>
              <CardImg>
                <img
                  alt="cloud-general"
                  src={`${process.env.PUBLIC_URL}/assets/reshot-icon-database-search-J8FH6B9KSQ.svg`}
                  width="80px"
                />
              </CardImg>
              <CardTitle>Armazenamento</CardTitle>
              <CardBody>
                Tenha todos os
                <span>dados</span> e <span>informções</span>, da sua empresa. <span>
                  Banco de dados</span>, <span>documentos</span>, <span>imagems</span> e <span>vídeo</span>.
              </CardBody>
            </Card>
          </Box>
        </Fade>
      </GridArea>
    </Container>
  );
};

export default Section4;

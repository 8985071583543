import styled from 'styled-components';

export type IProps = {
  baseColor?: string;
  borderShadow?: string;
};

export const Container = styled.div`
  min-height: 100vh;
  z-index: 1;
`;

export const FormArea = styled.div`
  width: 50%;
  margin: 0 auto;
  margin-top: 200px;
  background: #1a202c;
  border-radius: 15px;
  padding: 50px;
`;

export const FormFields = styled.div`
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  decoration: none;
`;

export const Title = styled.h1`
  text-align: center;
  font-weight: bold;
  font-size: 36px;
  font-weight: 700;
  color: #f8f8ff;
`;

export const Input = styled.input`
  height: 30px;
  font-size: 20px;
  font-weight: 300;
  background: #171923;
  border: none;
  color: #f8f8ff;
  padding: 10px;
  outline: none;
`;

export const TextArea = styled.textarea`
  height: 200px;
  font-size: 20px;
  font-weight: 300;
  background: #171923;
  border: none;
  color: #f8f8ff;
  padding: 10px;
  outline: none;
`;

export const SubmitButton = styled.button`
  min-width: 35%;
  margin-top: 20px;
  background: #4a5568;
  padding: 10px;
  border: none !important;
  color: #f8f8ff;
  line-height: 1.5;
  letter-spacing: -0.01em;
  list-style-position: outside;
  font-size: 140%;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
`;

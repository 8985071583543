import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Hero from '../pages/Hero';
import Metodology from '../pages/Metodology';
import Contact from '../pages/Contact';
import Congrats  from '../pages/Congrats';

const RouteSystem: React.FC = () => (
  <Routes>
    <Route path="/" element={<Hero />} />
    <Route path="/metodologia" element={<Metodology />} />
    <Route path="/contato" element={<Contact />} />
    <Route path="/congrats" element={<Congrats />} />
  </Routes>
);

export default RouteSystem;

import styled from 'styled-components';

export const Container = styled.div`
  /* witdh: 200px;
  ming-height: 90px; */
`;

export const Link = styled.a`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  direction: row;
  align-items: center;
  min-height: 10vh;
`;

export const Image = styled.img`
  alt: 'Codar BH';
  width: 100px;
  color: '#fff';

  @media (max-width: 820px) {
    width: 80px;
  }

  @media (max-width: 520px) {
    width: 60px;
  }
`;

export const TextLogo = styled.p`
  cursor: pointer;
  color: #f8f8ff;
  line-height: 1.5;
  letter-spacing: -0.01em;
  list-style-position: outside;
  font-size: 42px;
  font-weight: 800;
  text-align: center;
  margin-left: -0.3em;
  margin-top: 0;
  margin-bottom: 0;

  span {
    font-style: italic;
    color: #7ed957;
  }

  @media (max-width: 820px) {
    font-size: 32px;
  }

  @media (max-width: 520px) {
    font-size: 26px;
  }
`;

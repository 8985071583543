import React from 'react';
import Footer from '../Footer/index';
import Section1 from './Sections/Section1';

const Contact: React.FC = () => {
  return (
    <>
      <Section1 />
      <Footer />
    </>
  );
};

export default Contact;

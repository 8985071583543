import React, {  useState } from 'react';
import Logo from '../../../../components/Logo';
import NavBar from '../../../../components/NavBar';
import {
  Container,
  ContentHeader,
  ContentHeaderLogo,
  ContentHeaderNav,
  Header,
  Promo,
  PromoDescription,
} from './styles';
import CongratsPage from '../CongratsPage';

const Section1: React.FC = () => {
  const [isRolling] = useState<boolean>(false);


  return (
    <Container>
      <Header
        baseColor="#1A202C"
        borderShadow={isRolling ? '0px 10px 10px rgb( 0 0 0  / 10%)' : 'none'}
      >
        <ContentHeader>
          <ContentHeaderLogo>
            <Logo />
          </ContentHeaderLogo>
          <ContentHeaderNav>
            <NavBar />
          </ContentHeaderNav>
        </ContentHeader>
        <Promo>
          <PromoDescription>
            Ganhe 1 ano de hospedagem na AWS, fazendo o seu site conosco.
            <span> Saiba como.</span>
          </PromoDescription>
        </Promo>
      </Header>
      <CongratsPage />
    </Container>
  );
};

export default Section1;

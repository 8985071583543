/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {FaCheckCircle} from 'react-icons/fa'

import {
  CongratsArea,
  Container,
  Description,
  SentConfirmation,
  Title,
  TitleText
} from './styles';

const CongratsPage: React.FC = () => {

  return (
    <Container>
      <CongratsArea>
        <SentConfirmation>
          <Title>
            <FaCheckCircle size={100} color="#006400"/>
            <TitleText>Legal, email enviado!</TitleText>
          </Title>
        </SentConfirmation>
      </CongratsArea>
    </Container>
  );
};

export default CongratsPage;

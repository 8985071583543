import styled from 'styled-components';

export type IProps = {
  baseColor?: string;
  borderShadow?: string;
};

export const Container = styled.div`
  min-height: 100vh;
  z-index: 1;
`;

export const Header = styled.header<IProps>(
  ({ baseColor, borderShadow }) => `
  transition: 0.3s ease-in;
  top: 0;
  width: 100%;
  background: ${baseColor};
  position: fixed;
  z-index: 19000;
  box-shadow: ${borderShadow};
  min-height: 10vh;
  z-index: 9;`,
);

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
`;

export const ContentHeaderLogo = styled.div`
  width: 30%;
  align-items: center;
  min-height: 10vh;
`;

export const ContentHeaderNav = styled.div`
  width: 60%;
  align-items: center;
`;

export const Promo = styled.div`
  min-height: 5vh;
  background: #006400;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PromoDescription = styled.p`
  font-size: 16px;
  font-weight: 300;
  color: #f8f8ff;
  padding-left: 30px;
  padding-right: 30px;

  span {
    text-decoration: none;
    font-weight: 600;
    color: #f8f8ff;
    cursor: pointer;
  }
`;
